import './header.sass'
export function Header({titulo,img,icon,subtitulo}) {
    return (<>
        <div>
            <div className="header-bar" style={{background: `url(${img})`}}>
                <div className="container" >
             <div className='my-auto texto '>
             <p>
             <i class={icon}></i>  {subtitulo}
                    </p>
             <h1 className="header">
                       {titulo}
                    </h1>
                  </div>
    
                </div>
            </div> 
   
        </div>

    </>)
}