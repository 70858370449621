import { useContext } from "react";
import { QrCodeAppContext } from "../../../template/qrcodeappContext";
import "./bemvindo.sass";
const charPoses = {
  exit: { opacity: 0, x: 20 },
  enter: {
    opacity: 1,
    x: 0
  }
};

export function BemVindo() {
  const {isOpen,toggle} = useContext(QrCodeAppContext)
  return (
    <>
      <div style={{  background: 'rgba( 248,249,250)'}}  className="bemvindo">
        <div className="efeito">
            <img src="efeito.svg" alt="efeito de fundo"/>
        </div>
        <div className="pt-5 container">
    <div className="row">
      <div className="col-sm-7 flex">
            <div className="m-auto">
            <h1>
           <>
           Bem-vindo à liberdade.<br></br>
              Bem-vindo ao IBi pay.
           </>
       
            </h1>
            <h2>
              
              Seu novo banco digital sem burocracia, <br>
              </br> com praticidade, agilidade
              e eficiência.
            </h2>
            <div>
              <a
                className="btn btn-primary"
                target="_blankcode "
                onClick={() => toggle(true)}
              >
             Abra sua conta digital
              </a>
            </div>
            </div>
       
         
          </div>
          <div className="col-sm-5 flex order-mobile">
      <div className="img ms-auto flex ">
            <img src="img_slider.png" alt="cartão ibipay e mock de celular com a logo da ibipay" className="m-auto" />
          </div>
      </div>
    </div>
          
        </div>
      </div>
    </>
  );
}
