import React,{ memo } from "react";
import { Pagina } from '../../template/pagina/pagina';

export default memo(function TireSuasDuvidasPagina() {

return(<>
<Pagina>
<>

</>
</Pagina>
</>);

});