import * as React from 'react';
import './rodape.sass'
export function Rodape() {
   
    return (<> 
<footer>
  <div className='container'>
  <div className="row">
  <div className="col-sm-5">
     <>
     <img className='logo' src='../logo.png'  alt="logo ibipay rodape" height={50} style={{margin: '2rem 0'}}></img>
     <p style={{fontSize: '1.2rem'}}>
     Sobre o IBi Pay

     </p>
     <p style={{fontSize: '.9rem'}}>
Atuamos no segmento de serviços<br></br>
financeiros,entre outras opções<br></br>
bancárias facilitadas.
</p>
<p style={{fontSize: '.9rem'}}>
Av. Trez  de Julho, 50 - Jardim Aclimação, São Paulo - SP.<br></br>
00000-000
</p>
     </>
    </div>
    <div className="col-sm">
    <h4>Produtos e Serviços</h4>
    <ul class="menu-rodape">
    <li class="list-group-item">Cartões</li>
    <li class="list-group-item">Pix</li>
    <li class="list-group-item">Investimentos</li>
    <li class="list-group-item">Credito</li>
    <li class="list-group-item">Portabilidade de credito</li>

</ul>
<h4>Empresa</h4>
<ul class="menu-rodape">
    <li class="list-group-item">Sobre nós</li>
    <li class="list-group-item">Clientes </li>
    <li class="list-group-item">Fale conosco</li>

</ul>
    </div>
    <div className="col-sm">
     
<p className='info'>Central de Relacionamento
Segunda a sexta, das 8h às 20h
e sábados das 8h às 14h (exceto feriados)</p>
<p className='icon'>
<i class="fa-solid fa-phone"></i>
  <span>
  Capitais e regiões metropolitanas<br></br>
0000-0000
  </span>
</p>

<p className='icon'>
<i class="fa-solid fa-phone"></i>
  <span>
  Outras localidades<br></br>
0800 000 0000
  </span>
</p>

<p className='icon'>
<i class="fa-solid fa-phone"></i>
  <span>
  Capitais e regiões metropolitanas<br></br>
0000-0000
  </span>
</p>

<p className='icon'>
<i class="fa-brands fa-whatsapp"></i>
  <span>
  Deficientes auditivos ou de fala
WhatsApp<br></br>
11 1010  3300
  </span>
</p>

<p className='icon'>
<i class="fa-solid fa-phone"></i>
  <span>
  SAC (Reclamações, dúvidas e sugestões)<br></br>
atendimento 24h<br></br>
0800 000  0000
  </span>
</p>

<p className='icon'>
<i class="fa-solid fa-phone"></i>
  <span>
  Ouvidoria<br></br>
segunda a sexta, das 10h às 16h<br></br>
(exceto feriados)<br></br>
0800 000 0000
  </span>
</p>



    </div>
  </div>
  </div>
  <div className='bar container'>
  <div className="row">
    <div className='col-sm d-flex flex-row py-3 links'>
    <a>Política de Privacidade</a>
    <a>Proteção de Dados</a>
    <a>Política de Cookies</a>
    </div>
    <div className='col-sm d-flex redes py-3'>
    {/* <a>
    <i class="fa-brands fa-twitter"></i>
        </a> */}
        <a href='https://www.facebook.com/Ibipaybank/' target="_blank">
        <i class="fa-brands fa-facebook-f"></i>
        </a>
        <a>
        <i class="fa-brands fa-linkedin-in"></i>
        </a>
        <a href='https://www.instagram.com/ibi.pay/' target="_blank">
        <i class="fa-brands fa-instagram"></i>
        </a>

    </div>
  </div>
  </div>
  <div className='copyright'>
    <div className='container'>
    IBi Pay  © 2023 - Todos os direitos reservados CNPJ: 32.789..000/0001-99
    </div>
</div>
</footer>

    </>)
}