import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./contapj.sass";
export function ContaPj() {
  return (
    <>
      <div className="contapj">
        <Container >
        <img className="img-bg" src="./dois-parceiros-de-negocios-trabalhando-no-escritorio.jpg" alt="dois parceiros de negocios trabalhando no escritorio" />
        <Row>
        
        <Col sm="6" className=" d-flex ">

<div className="m-auto texto">
  <div className="texto-left">
  <h2>Você é um empreendedor e busca por uma conta PJ que atenda às necessidades do seu negócio?  </h2>
  </div>
<p>

Então você está no lugar certo! A Ibipay apresenta a Conta PJ feita para a sua empresa.</p>
<div>
<Link className=" btn btn-primary" to={'/conta-digital#contapj'}>
<i class="fa-solid fa-circle-arrow-right"></i>        Ver conta digital PJ
</Link>
</div>
</div>

</Col>
    
        </Row>
        </Container>
   

      
      </div>
    </>
  );
}
