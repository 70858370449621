import { useContext } from "react";
import { QrCodeAppContext } from "../../../template/qrcodeappContext";
import "./abrasuaconta.sass";
export function AbraSuaConta() {
  const {isOpen,toggle} = useContext(QrCodeAppContext)

  return (
    <>
      <div className="abrasuaconta">
        <div className="efeito">
            <img src="efeito.svg"/>
        </div>
  
        <div className="container">

          <div className="row">
          <div className="col-sm-4">
          <div className="img ms-auto flex ">
            <img src="celularmao.png"  alt="Mão segurando um celular com a imagem da loja google play para baixar o aplicativo!"  style={{zIndex: '999'}} height={500} className="m-auto" />
          </div>
          </div>
          <div className="col-sm-8">

          <div className="flex">
            <div className="m-auto texto">
            <h3>
            Baixe o Aplicativo do IBi Pay<br></br>
e abra sua conta agora em minutos.
            </h3>
          
            <div>
              <a
                className="ui btn btn-primary"
                target="_blankcode "
                onClick={() => {toggle(true)}}
              >
       Abra sua conta digital
              </a>

              
            </div>
            <div >
            <img src="./icones/google-badge.png" alt="imagem download google play"/>
            <img src="./icones/apple-badge.png" alt="imagem download App Store"/>
            </div>
            </div>
       
         
          </div>
          </div>
          </div>
    
    
         
        </div>
      </div>
    </>
  );
}
