import  { createContext } from 'react';

export const QrCodeAppContext = createContext(

    {
        isOpen: false,
        toggle: () => {},
      }
);

