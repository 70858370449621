import React,{ memo, useContext } from "react";
import { Card, CardGroup, Col, Row } from "react-bootstrap";
import { Pagina } from '../../template/pagina/pagina';
import { PaginaEscura } from "../../template/pagina/paginaescura";
import { QrCodeAppContext } from "../../template/qrcodeappContext";
import './sobre.sass'
export default memo(function SobrePagina({}) {
  const {isOpen,toggle} = useContext(QrCodeAppContext)

return(<>
<Pagina escuro={true}>
<>

<div className="sobre-bemvindo" style={{background: `url("./quemsomos.jpg")`}}>
<div className="container" >
<div className="row py-4">
<div className="col-sm-8">

<div className="card bg-dark text-light">
        <div className="card-body">
        <h2>Olá!
Bem-vindo ao Ibipay, o banco digital que está revolucionando o mercado financeiro!</h2>

<p>
Ibi é um nome único e cheio de significado. Derivado da língua tupiguarani, Ibi significa "terra forte", uma expressão que representa força, estabilidade e confiança. E essas são exatamente as qualidades que nosso banco digital busca transmitir aos nossos clientes.
O Ibipay foi criado com a missão de oferecer uma experiência bancária simples, ágil e acessível a todos os brasileiros. Sabemos que o sistema financeiro tradicional pode ser complicado e excludente, e é por isso que estamos trabalhando duro para mudar isso.
Nossa equipe é composta por profissionais altamente qualificados e apaixonados pelo que fazem. Estamos constantemente buscando novas formas de inovar e melhorar a vida de nossos clientes. Queremos que todos os brasileiros tenham acesso a serviços financeiros de qualidade, independentemente de sua renda ou de onde vivem.
No Ibipay, colocamos a emoção em tudo o que fazemos. Acreditamos que, ao fazer negócios com paixão e entusiasmo, podemos criar uma relação mais próxima e duradoura com nossos clientes. É por isso que estamos sempre prontos para ouvir o feedback de nossos clientes e melhorar nossos serviços.
O Ibipay é um banco digital que combina a força e a estabilidade da terra com a agilidade e a inovação do mundo digital.
Estamos comprometidos em oferecer uma experiência bancária simples, acessível e emocionante a todos os brasileiros.
</p>

<div className="btn btn-primary" onClick={() => toggle(true)}>
Abra sua conta digital
</div>
        </div>
</div>

        </div>
        <div className="col-sm">
        </div>
</div>

</div>
</div>
<div className="vantagens">
<div className="container">
<h2 className="title">
Conheça os <strong>benefícios</strong> <br></br> ao abrir sua conta IBi Pay
</h2>
<Row sm="3" className="g-4 beneficios">
  
        <Col sm>
        <Card bg="dark" text="light" className="p-4" >
        <Card.Body>
          <Card.Title> <i class="fa-solid fa-hand-holding-dollar"></i> Serviços on-line</Card.Title>
          <Card.Text>
            This is a wider card with supporting text below as a natural lead-in
            to additional content. This card has even longer content than the
            first to show that equal height action.
          </Card.Text>
        </Card.Body>

      </Card>
      </Col>
      <Col sm>
      <Card bg="dark" text="light" className="p-4" >
        <Card.Body>
          <Card.Title> <i class="fa-solid fa-star"></i> Aplicativo com acesso fácil</Card.Title>
          <Card.Text>
          Nosso aplicativo possui acesso fácil para trazer praticidade, agilidade e eficiência para suas atividades bancárias.
          </Card.Text>
        </Card.Body>

      </Card>
      </Col>
      <Col sm>
      <Card bg="dark" text="light" className="p-4" >
        <Card.Body>
          <Card.Title> <i class="fa-solid fa-mobile-screen-button"></i> Recarga de Celular</Card.Title>
          <Card.Text>
          Recarregue seu celular diretamente da plataforma digital por débito em conta.
          </Card.Text>
        </Card.Body>

      </Card>
      </Col>

      <Col sm>
      <Card bg="dark" text="light" className="p-4" >
        <Card.Body>
          <Card.Title><i class="fa-solid fa-receipt"></i> Pagamentos de contas e boletos</Card.Title>
          <Card.Text>
          Realize pagamentos de
contas direto pelo aplicativo.
Sem filas e sem atrasos.
          </Card.Text>
        </Card.Body>

      </Card>
      </Col>

      <Col sm>
      <Card bg="dark" text="light" className="p-4" >
        <Card.Body>
          <Card.Title> <i class="fa-brands fa-pix"></i>Pix</Card.Title>
          <Card.Text>
          Faça transferências via Pix
em poucos minutos.
O dinheiro cai na sua conta
na hora e com toda segurança.
          </Card.Text>
        </Card.Body>

      </Card>
      </Col>

      <Col sm>
      <Card bg="dark" text="light" className="p-4" >
        <Card.Body>
          <Card.Title><i class="fa-solid fa-money-bill-transfer"></i>Transferências</Card.Title>
          <Card.Text>
          Envie e receba seu dinheiro de
forma rápida e sem burocracias.
          </Card.Text>
        </Card.Body>

      </Card>
      </Col>

  

    </Row>
<div className="d-flex p-4">
<div className="btn btn-primary mx-auto" onClick={() => toggle(true)}>
Baixe o aplicativo
</div>
</div>
</div>
</div>
<div className="conheca">
<div className="container">
<div className="row py-4">
<div className="col-sm flex">
        <div className="m-auto">
        <h2>
Conheça o IBi Pay <br></br>
Conheça a liberdade!<br></br>
</h2>
<p>
banco digital que está revolucionando o mercado financeiro!
</p>
        </div>

</div>
<div className="col-sm flex">
<img src="celularmao.png"  style={{zIndex: '999'}} height={500} className="m-auto" />
</div>
</div>
</div>
</div>
</>
</Pagina>
</>);

});