import React, { useRef, useState,memo } from "react";
import Nav from 'react-bootstrap/Nav';

// Import Swiper styles
import "./servicos.sass";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export function Servicos() {
  const [swiperRef, setSwiperRef] = useState(null);
  const [activeSlide, setActiveSlide] = useState(0);

  return (
 
      <div  className="servicos">
        <div className=" container">
      <div className="row">
      <div className="col-sm-5">
        <div className="img ">
            <img src="servicos_image.png" alt="mulher pensativa" className="m-auto" />
          </div>
        </div>
        <div className="col-sm-7">
            <div className="m-auto texto-servicos">
            <h3>
            Um banco que <strong>
            acredita no potencial <br></br>
das pessoas,</strong> na inclusão financeira e social para<br></br>
gerar igualdade de oportunidades para <br></br>
centenas de <strong> pessoas e empresas em todo o Brasil</strong>.
            </h3>
            <Nav  className="servicos-nav" defaultActiveKey="/home">
      <Nav.Item active="true">
        <Nav.Link href="/home" >PARA VOCÊ</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="link-1">PARA SUA EMPRESA</Nav.Link>
      </Nav.Item>
    </Nav>


    {/* <div className="flex items"> */}
    <Carousel
  swipeable={true}
  draggable={true}
  showDots={true}
  responsive={ {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3,
      paritialVisibilityGutter: 20

    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2 ,
      paritialVisibilityGutter: 20

    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 ,
      paritialVisibilityGutter: 40

    }
  }}
  ssr={true} // means to render carousel on server-side.
  customTransition="all .5"
  partialVisbile
  transitionDuration={500}
  arrows={false}
  containerClass="carousel-container"
  itemClass="carousel-item-padding-40-px items"
>
          <div className="item">
      <i className="fa-solid fa-dollar-sign"></i>


            <p>Conta Digital</p>
          </div>

          
   
     

 <div className="item">
          <i className="fa-brands fa-pix"></i>
          <p>PIX</p>
          </div>


  <div className="item">
        <i className="fa-solid fa-money-bill-transfer"></i>

            <p>Transferências</p>
          </div>
    
 
    <div className="item">
        <i className="fa-regular fa-credit-card"></i>
          <p>Cartão de Crédito
e adiciona</p>

          </div>
     
 
    <div className="item">
          <i className="fa-solid fa-hand-holding-dollar"></i>
            <p>Pague
contas</p>
          </div>

        
       




            </Carousel>
            </div>
       
         
        </div>

      </div>
   
       
        </div>
      </div>
    
  );
}
