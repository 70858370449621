import { useContext } from "react";
import { QrCodeAppContext } from "../../../template/qrcodeappContext";
import "./venha.sass";
export function VenhaParaIbipay() {
  const {isOpen,toggle} = useContext(QrCodeAppContext)

  return (
    <>
      <div className="venhapara">
 
        <div className=" container">
          <div className="row">
          <div className="col-sm-7 " style={{position: 'relative'}}>
            <img src="cartoes.png" className="cartoes" alt="Imagem dos cartões da ibi pay verde verde vermelho azul rosa verde neon laranja e azul" />
          </div>
          <div className="col-sm-5">
            <div className="m-auto texto">
            <h2>
            Venha para <br></br>
<span style={{color: "#15CDDC"}}>IBi pay</span>
            </h2>
            <p>
            Venha para o Ibipay, o banco que está revolucionando o mercado financeiro com soluções inovadoras e tecnologia de ponta.
            </p>
            <p>
Com o Ibipay, você terá acesso a uma ampla variedade de serviços bancários. Além disso, oferecemos taxas competitivas e um atendimento personalizado para atender às suas necessidades financeiras.
</p>
            <p>
Não perca a oportunidade de fazer parte dessa revolução bancária. Clique no botão abaixo para se cadastrar e começar a usar o Ibipay hoje mesmo!
            </p>
            <div>
              <a
              onClick={() => toggle(true)}
                className="ui btn btn-primary"
                target="_blankcode "
              >
             Abra sua conta digital
              </a>
            </div>
            </div>
            </div>
            </div>
        
        </div>
      </div>
    </>
  );
}
