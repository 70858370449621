import React from 'react'
import { createBrowserRouter } from 'react-router-dom';
import ContadigitalPagina from '../paginas/contadigital';
import PaginaPrincipal from '../paginas/principal/index'
import ContatoPagina from '../paginas/contato/index'
import SobrePagina from '../paginas/sobre/index'
import TiresuasduvidasPagina from '../paginas/tiresuasduvidas';
import PoliticaDePrivacidadePagina from '../paginas/politicas/politica-de-privacidade';
 const routes = createBrowserRouter([
    { 
    path: '',
    element: <PaginaPrincipal />,
    },
    {
        path: '/',
        titulo: 'O grupo',
        children: [
            {
                path: '/sobre',
                element: <SobrePagina />

            },
            {
                path: '/conta-digital',
                element: <ContadigitalPagina />

            },
            {
                path: '/fale-conosco',
                element: <ContatoPagina />

            },
            {
                path: '/tire-suas-duvidas',
                element: <TiresuasduvidasPagina />

            },
            {
                path: '/tire-suas-duvidas',
                element: <SobrePagina />

            },
            {
                path: '/politica-de-privacidade',
                element: <PoliticaDePrivacidadePagina />

            }
            
           
            
         
        ]
    }


])
export default routes;