import { Link } from "react-router-dom";
import "./sobre.sass";
export function Sobre() {
  return (
    <>
      <div className="sobre">
        <div className="container">
        <div className="row">
        <div className="col-sm-6 d-flex ">

<div className="m-auto texto">
  <div className="texto-left">
  <h3>Sobre o </h3>
  <h4>IBi Pay</h4>
  </div>

<p>
Olá!
Bem-vindo ao Ibipay, o banco digital que está revolucionando o mercado financeiro!

Ibi é um nome único e cheio de significado. Derivado da língua tupiguarani, Ibi significa "terra forte", uma expressão que representa força, estabilidade e confiança. E essas são exatamente as qualidades que nosso banco digital busca transmitir aos nossos clientes.

O Ibipay foi criado com a missão de oferecer uma experiência bancária simples, ágil e acessível a todos os brasileiros. Sabemos que o sistema financeiro tradicional pode ser complicado e excludente, e é por isso que estamos trabalhando duro para mudar isso....
</p>
<div>
<Link className="link" to={'/sobre'}>
<i class="fa-solid fa-circle-arrow-right"></i>        Ver institucional
</Link>
</div>
</div>

</div>
        <div className="col-sm-6 order-mobile" style={{position: 'relative'}}>
        <div className="efeito "  alt="Mulher segurando cartão IBi Pay Azul" style={{backgroundImage: `url('./mulheribippay.png')`}}>
   
        </div>

        </div>
    
        </div>
        </div>
   

      
      </div>
    </>
  );
}
