import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import "./topo.sass";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import { QrCodeAppContext } from "../qrcodeappContext";

export function Topo({escuro}) {
  const {isOpen,toggle} = useContext(QrCodeAppContext)

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [window.location]);
  return (
    <>
      <Navbar bg={escuro ? 'dark' : 'light' } variant={escuro ? 'dark' : 'light' } t expand="lg" className="topo" sticky="top">
        <Container>
            <Link className="nav-brand" style={{width: '180px'}} to="/" preventScrollReset={true} >
              {escuro ? <img src="../logo_dark.png" className="logo"   alt="logo ibipay"/> :   <img src="../logo.png"  alt="logo ibipay" className="logo" />}
            </Link>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${"lg"}`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${"lg"}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${"lg"}`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${"lg"}`}>
              <Link className="nav-brand" to="/" preventScrollReset={true} >
              <img src="../logo.png"  style={{width: '180px'}}  className="logo" alt="logo ibipay" />
            </Link>
       
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
    
               <Nav.Item> 
               <Link className="nav-link" to="/sobre" preventScrollReset={true} >
               Sobre Ibi Pay
               </Link>
               </Nav.Item>
               <Nav.Item>
               <Link className="nav-link" to="/conta-digital" preventScrollReset={true} >
               Conta digital
               </Link>
               </Nav.Item>
        
               <Nav.Item>
               <Link className="nav-link" to="/fale-conosco" preventScrollReset={true} >
               Fale conosco
               </Link>
               </Nav.Item>
               <Nav.Item>
               {/* <Link className="nav-link" to="/tire-suas-duvidas" preventScrollReset={true} >
               Tire suas dúvidas
               </Link> */}
               </Nav.Item>
               <Nav.Item >
               <Link className="nav-link" to="/politica-de-privacidade" preventScrollReset={true} >Política de Privacidade</Link>
                </Nav.Item>
     
              </Nav>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Item className="destaque" onClick={() => toggle(true)} > 
                     <a className="nav-link"  preventScrollReset={true} >Abra a sua conta</a>
                </Nav.Item>
              </Nav>

            
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>

      {/* 

<div  className={fixed ? 'navbar fixed-top  navbar-expand-lg bg-body-tertiary topo' : 'navbar navbar-expand-lg bg-body-tertiary topo'}>
  <div className="container">
    <a className="navbar-brand" href="#"><img src="./logo.png" className='logo'/></a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav m-auto mb-2 mb-lg-0">

        
        <li className="nav-item">
          <a className="nav-link" href="#">  Política de Privacidade</a>
        </li>
      </ul>
    
      <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
        <li className="nav-item destaque">
          <a className="nav-link " href="#">Abra a sua conta</a>
        </li>
    
      </ul>
    
    </div>
  </div>
</div> */}
    </>
  );
}
